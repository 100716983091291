<template>
  <div>
    <!-- Media -->
      <h2 class="mb-3">
        {{ agencyData.name }}
      </h2>
      <!--
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :text=agencyData.name
          :variant="`light-primary`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-3">
        {{ agencyData.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
          >
          <span class="d-none d-sm-inline">Modifier</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Supprimer</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
       
      </div>
      
    </b-media>
  -->
    <h5 class='mb-1'> Coordonnées </h5>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Nom de l'agence"
            label-for="agencyName"
          >
            <b-form-input
              id="agencyName"
              v-model="agencyData.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: phone Number -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Téléphone principal"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-mask="'(###) ###-####'"
              v-model="agencyData.phoneNumber"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Courriel principal"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="agencyData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Website  -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Site web"
            label-for="website"
          >
            <b-form-input
              id="website"
              v-model="agencyData.website"
              type="url"
            />
          </b-form-group>

        </b-col>
       
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
          v-if="agencyData.additionalInformation && agencyData.additionalInformation.branchNumber"
        >
          <b-form-group
            label="Numéro TDC"
            label-for="branchNumber"
          >
            <b-form-input
              id="branchNumber"
              v-model="agencyData.additionalInformation.branchNumber"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          v-if="pcVoyagesEnabled"
        >
          <b-form-group
            :label="$t('user_profile.pcvoyages_agency_branch_code')"
            label-for="pcVoyagesBranchCode"
          >
            <b-form-input
              id="pcVoyagesBranchCode"
              v-model="agencyData.pcVoyagesBranchCode"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>


    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="editAgency"

    >
      Enregistrer
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      :to="{ name: 'agencies'}"
    >
      Annuler
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import {useAgencyApi} from "@/modules/agency/composables/use-agency-api";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import {AUTH_STORE_NAMESPACE} from "@/modules/authnz/models/store";
import store from "@/store";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    agencyData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {updateAgency} = useAgencyApi();
    const {displaySuccessMessage, displayWarningMessage, displayErrorMessage} = useToastNotification();
    const pcVoyagesEnabled = store.state[AUTH_STORE_NAMESPACE].orgSettings.pcvoyages_enable;

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const editAgency = async () => {
      try {
        let id = props.agencyData.id;
        await updateAgency(id, props.agencyData);
        displaySuccessMessage('Les informations ont été sauvegardées avec succès.');

      } catch (e) {
        if (e.response.status == '403') {
          displayWarningMessage("Vous n'avez pas les droits pour modifier cette agence.");
        } else {
          displayErrorMessage("La sauvegarde d'information du groupe a échoué.");
        }
      }

    }
    
    return {
      avatarText,
      refInputEl,
      previewEl,
      pcVoyagesEnabled,
      editAgency,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
